import React, { useState, useMemo, useEffect } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { trackEvent } from '../utils/analytics';

interface StockData {
  date: string;
  price: number;
}

interface NewsItem {
  headline: string;
}

interface InsiderTrade {
  insider: string;
  shares: number;
  type: string;
}

interface InstitutionalHolder {
  "Date Reported": string;
  Holder: string;
  Shares: number;
  Value: number;
  pctHeld: number;
}

interface EarningsHistory {
  epsActual: number;
  epsDifference: number;
  epsEstimate: number;
  surprisePercent: number;
}

interface AnalystRatings {
  buy: number;
  hold: number;
  sell: number;
}

interface ApiResponse {
  stockData: StockData[];
  news: NewsItem[];
  insiderTrades: InsiderTrade[];
  institutionalHolders: InstitutionalHolder[];
  earningsHistory: EarningsHistory;
  analystRatings: AnalystRatings;
  analystPriceTarget: number;
}

const FinanceProject2: React.FC = () => {
  const [ticker, setTicker] = useState<string>('');
  const [data, setData] = useState<ApiResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showLongLoadingMessage, setShowLongLoadingMessage] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isLoading) {
      timeoutId = setTimeout(() => {
        setShowLongLoadingMessage(true);
      }, 5000);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [isLoading]);

  const fetchData = async () => {
    if (!ticker) return;
    setIsLoading(true);
    setShowLongLoadingMessage(false);
    setError(null);
    try {
      console.log(`Fetching data for ${ticker}...`);
      const response = await axios.get<ApiResponse>(`https://lean-finance.onrender.com/api/stock/${ticker}`);
      console.log('Raw API response:', response.data);
      setData(response.data);
      trackEvent('fetch_stock_data', JSON.stringify({ project: 'equity_dashboard', ticker }));
    } catch (error) {
      console.error('Error fetching data:', error);
      setData(null);
      setError(`Error fetching data: ${error instanceof Error ? error.message : String(error)}`);
      trackEvent('fetch_stock_data_error', JSON.stringify({ project: 'equity_dashboard', ticker, error: JSON.stringify(error) }));
    } finally {
      setIsLoading(false);
    }
  };

  const normalizedStockData = useMemo(() => {
    if (!data?.stockData) return [];
    return data.stockData.map(d => ({
      ...d,
      price: parseFloat(d.price.toFixed(2)) // Ensure price is rounded to 2 decimal places
    }));
  }, [data?.stockData]);

  const priceRange = useMemo(() => {
    if (normalizedStockData.length === 0) return { min: 0, max: 100 };
    const prices = normalizedStockData.map(d => d.price);
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);
    const padding = (maxPrice - minPrice) * 0.1; // Add 10% padding
    return {
      min: Math.max(0, minPrice - padding),
      max: maxPrice + padding
    };
  }, [normalizedStockData]);

  return (
    <div className="finance-project-2 bg-gray-800 rounded-lg shadow-md p-6 text-white">
      <h2 className="text-2xl font-bold mb-4 text-center">Stock Dashboard</h2>
      <div className="mb-4 flex justify-center">
        <input
          type="text"
          value={ticker}
          onChange={(e) => setTicker(e.target.value.toUpperCase())}
          placeholder="Enter stock ticker"
          className="mt-1 block w-64 rounded-md border-gray-600 bg-gray-700 text-white placeholder-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
        />
        <button
          onClick={fetchData}
          disabled={isLoading}
          className="ml-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:bg-blue-400"
        >
          {isLoading ? 'Loading...' : 'Get Data'}
        </button>
      </div>

      {isLoading && (
        <div className="text-center mt-4">
          <p>Loading data for {ticker}...</p>
          {showLongLoadingMessage && (
            <p className="mt-2 text-yellow-600">
              This request is taking longer than expected. It may take up to 1 minute to fetch the data. Please be patient.
            </p>
          )}
        </div>
      )}

      {error && <p className="text-center text-red-500 mt-4">{error}</p>}

      {data && (
        <div className="flex flex-col items-center">
          <div className="mb-6 w-full">
            {normalizedStockData.length > 0 && (
              <div className="mb-6 w-full">
                <h3 className="text-xl font-semibold mb-2 text-center">Stock Price Chart</h3>
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={normalizedStockData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis 
                      domain={[priceRange.min, priceRange.max]} 
                      tickFormatter={(value) => `$${value.toFixed(2)}`}
                    />
                    <Tooltip 
                      formatter={(value: number) => [`$${value.toFixed(2)}`, "Price"]}
                      labelFormatter={(label) => `Date: ${label}`}
                    />
                    <Legend />
                    <Line type="monotone" dataKey="price" stroke="#8884d8" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            )}
          </div>

          <div className="mt-6 w-full max-w-4xl">
            {/* Earnings History Table */}
            <h3 className="text-xl font-semibold mb-2">Earnings History</h3>
            <table className="w-full mb-6 border-collapse border border-gray-700">
              <thead>
                <tr className="bg-gray-700">
                  <th className="border border-gray-600 p-2">EPS Actual</th>
                  <th className="border border-gray-600 p-2">EPS Estimate</th>
                  <th className="border border-gray-600 p-2">Difference</th>
                  <th className="border border-gray-600 p-2">Surprise %</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-gray-600 p-2">${data.earningsHistory.epsActual.toFixed(2)}</td>
                  <td className="border border-gray-600 p-2">${data.earningsHistory.epsEstimate.toFixed(2)}</td>
                  <td className="border border-gray-600 p-2">${data.earningsHistory.epsDifference.toFixed(2)}</td>
                  <td className="border border-gray-600 p-2">{(data.earningsHistory.surprisePercent * 100).toFixed(2)}%</td>
                </tr>
              </tbody>
            </table>

            {/* Insider Trades Table */}
            <h3 className="text-xl font-semibold mb-2">Insider Trades</h3>
            <table className="w-full mb-6 border-collapse border border-gray-700">
              <thead>
                <tr className="bg-gray-700">
                  <th className="border border-gray-600 p-2">Insider</th>
                  <th className="border border-gray-600 p-2">Type</th>
                  <th className="border border-gray-600 p-2">Shares</th>
                </tr>
              </thead>
              <tbody>
                {data.insiderTrades.map((trade, index) => (
                  <tr key={index}>
                    <td className="border border-gray-600 p-2">{trade.insider}</td>
                    <td className="border border-gray-600 p-2">{trade.type}</td>
                    <td className="border border-gray-600 p-2">{typeof trade.shares === 'number' ? trade.shares.toLocaleString() : 'N/A'}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Institutional Holders Table */}
            <h3 className="text-xl font-semibold mb-2">Institutional Holders</h3>
            <table className="w-full mb-6 border-collapse border border-gray-700">
              <thead>
                <tr className="bg-gray-700">
                  <th className="border border-gray-600 p-2">Holder</th>
                  <th className="border border-gray-600 p-2">Shares</th>
                  <th className="border border-gray-600 p-2">Value</th>
                  <th className="border border-gray-600 p-2">% Held</th>
                </tr>
              </thead>
              <tbody>
                {data.institutionalHolders.map((holder, index) => (
                  <tr key={index}>
                    <td className="border border-gray-600 p-2">{holder.Holder}</td>
                    <td className="border border-gray-600 p-2">{holder.Shares.toLocaleString()}</td>
                    <td className="border border-gray-600 p-2">${holder.Value.toLocaleString()}</td>
                    <td className="border border-gray-600 p-2">{(holder.pctHeld).toFixed(2)}%</td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Analyst Price Target */}
            <h3 className="text-xl font-semibold mb-2">Analyst Price Target</h3>
            <p className="text-lg">{typeof data.analystPriceTarget === 'number' ? `$${data.analystPriceTarget.toFixed(2)}` : 'N/A'}</p>
          </div>
        </div>
      )}

      {!isLoading && !data && !error && (
        <p className="text-gray-400 mt-4 text-center">No data available. Please enter a valid stock ticker and click "Get Data".</p>
      )}
    </div>
  );
};

export default FinanceProject2;