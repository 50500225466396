import ReactGA from 'react-ga4';

// Declare the gtag function
declare global {
  interface Window {
    gtag: (command: string, eventName: string, eventParams?: any) => void;
  }
}

// Function to track page views
export const trackPageView = (path: string) => {
  ReactGA.send({ hitType: "pageview", page: path });
};

// Function to track custom events
export const trackEvent = (category: string, action: string, label?: string, value?: number) => {
  ReactGA.event({
    category,
    action,
    label,
    value
  });
};

// Helper function to create a debounced version of a function
const debounce = (func: Function, delay: number) => {
  let timeoutId: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

// Debounced version of trackEvent
export const debouncedTrackEvent = debounce(trackEvent, 300);

// Function to initialize GA
export const initGA = (measurementId: string) => {
  ReactGA.initialize(measurementId);
};

