import React, { useState } from 'react';
import { FaLinkedin } from 'react-icons/fa';

const Footer: React.FC = () => {
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const toggleDisclaimer = () => {
    setShowDisclaimer(!showDisclaimer);
  };

  return (
    <footer className="bg-gray-800 text-white py-4" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <div className="footer-content">
        <div className="footer-main">
          <div className="footer-item">
            <a href="https://bellsdrones.com" target="_blank" rel="noopener noreferrer" className="text-gray-200 hover:text-white">
              Bell's Drones
            </a>
          </div>
          <div className="footer-item">
            <button 
              onClick={toggleDisclaimer}
              className="disclaimer-button"
            >
              Disclaimer
            </button>
          </div>
          <div className="footer-item">
            <p className="copyright footer-copyright">&copy; 2024 Austin Bell. All rights reserved.</p>
          </div>
          <div className="footer-item">
            <a href="https://www.linkedin.com/in/austin-bell-9a0082180/" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
              <FaLinkedin size={36} />
            </a>
          </div>
        </div>
        {showDisclaimer && (
          <div className="disclaimer-text">
            <p>
            The information provided herein is for general informational purposes only and should not be considered as financial advice. This content is not intended to be a substitute for professional financial advice, investment advice, or any other type of advice tailored to your specific financial situation.
            </p>

            <p>
            Always seek the advice of a qualified financial advisor or other professional who can take into account your individual circumstances before making any financial decisions. Any actions you take based on the information presented here are strictly at your own risk.
            </p>

            <p>
            Past performance is not indicative of future results. Investments carry risk, and the value of investments can go up as well as down. You may receive back less than you originally invested.
            </p>

            <p>
            The author and publisher of this content are not responsible for any losses or damages resulting from the use of the information provided.
            </p>
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
